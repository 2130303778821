import { call, put, takeLatest } from "redux-saga/effects";
import { sortBy } from 'lodash'
import { FIND_CARE_REQUEST, FIND_CARE_SUCCESS, FIND_CARE_FAILURE, FIND_CARE_MORE_REQUEST, FIND_CARE_MORE_SUCCESS, FIND_CARE_MORE_FAILURE } from "../constants";
import { api } from "../services";

function* findCareResult(action) {
  try {
    const { serviceLines } = action.payload

    let result = null

    if (serviceLines && serviceLines.length > 0) {
      const tasks = []

      for (const serviceLine of serviceLines) {
        tasks.push(api.getFindCare({ ...action.payload, serviceLines: serviceLine.name, pcpFlag: serviceLine.isPcp }))
      }

      const tasksResults = yield Promise.all(tasks)

      const results = sortBy(tasksResults.filter(r => r.data && r.data.results).map(r => r.data.results).flat(), ['practiceSummary.distance'])
      const successResult = tasksResults.find(r => r.data)

      if (successResult && successResult.data) {
        successResult.data.results = results
        successResult.data.hasMoreData = !!tasksResults.find(r => r.data && r.data.hasMoreData)

        if (serviceLines.length === 1 && serviceLines[0].name === "Pediatric Primary Care") {
          successResult.data.results = results.filter(el => !el.providerSummary.minAge || parseInt(el.providerSummary.minAge, 10) < 18)
        }
      }

      result = {
        ...successResult,
        results
      }
    }

    if (!result)
      result = yield call(api.getFindCare, action.payload);

    if (result.data && result.status === 200) {
      if (action.data && action.data.results && action.data.results.length > 0) {
        action.data.results.push(...result.data.results);
      } else {
        action.data = result.data;
      }
      if (result.data.hasMoreData) {
        const newAction = { ...action };
        newAction.payload.start = result.data.start + 1000;
        newAction.payload.end = result.data.end + 1000;
        yield call(findCareResult, newAction);
      } else {
        yield put({ type: FIND_CARE_SUCCESS, payload: action.data });
      }
    } else {
      let message = 'An error occurred while searching. If searching by Zip Code, try limiting the distance of the search.';
      if (result?.data?.error?.message) {
        message = result.data.error.message;
      }
      yield put({ type: FIND_CARE_FAILURE, payload: message });
    }
  } catch (error) {
    yield put({ type: FIND_CARE_FAILURE, payload: 'An error occurred while searching. If searching by Zip Code, try limiting the distance of the search.' });
  }
}

function* findCareMoreResult(action) {
  try {
    const result = yield call(api.getFindCareMore, action.payload);
    if (result.data && result.status === 200) {
      yield put({ type: FIND_CARE_MORE_SUCCESS, payload: result.data });
    } else {
      let message = '';
      if (result?.data?.error?.message) {
        message = result.data.error.message;
      }
      yield put({ type: FIND_CARE_MORE_FAILURE, payload: message });
    }
  } catch (error) {
    yield put({ type: FIND_CARE_MORE_FAILURE, payload: error });
  }
}

export function* findCareWatcherSaga() {
  yield takeLatest(FIND_CARE_REQUEST, findCareResult);
}

export function* findCareMoreWatcherSaga() {
  yield takeLatest(FIND_CARE_MORE_REQUEST, findCareMoreResult);
}
